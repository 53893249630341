import ItemLine from './item-line';
import utilReceiptPOS from '../util/util-receipt-pos';
import printManager from '../print-manager';
import QRCode from 'qrcode';

class Order {
	constructor() {
		this.balance = 0;
		this.bill_cash_register_id = null;
		this.bill_cash_register_turn_id = null;
		this.bus_person_cashier_id = null;
		this.bus_person_customer_id = null;
		this.bus_person_domiciliary_id = null;
		this.bus_person_seller_id = null;
		this.bus_subsidiary_id = null;

		this.canceled = false;
		this.cancellation_date = null;
		this.cancellation_reason = '';
		this.closing_date = null;
		this.coupon = null;
		this.consecutive_order = null;
		this.consecutive_order_text = null;

		this.data_cashier = null;
		this.data_consecutive = null;
		this.data_customer = null;
		this.data_domiciliary = null;
		this.data_items = [];
		this.data_payments = null;
		this.data_seller = null;
		this.data_taxes = null;
		this.date_order = null; //new Date();
		this.due_date = null;
		this.date_sync = null;


		this.total_discounts = 0;
		this.is_on_table = false;
		this.is_domicile = false;
		this.is_electronic_invoice = false;
		this.is_separated = false;
		this.is_open = true;
		this.is_sync = false;
		this.invoice_was_generated = false;
		this.invoice_id = null;

		this.local_reference = this.createReference();
		this.name_table = '';
		this.note = '';
		this.order_status = 'created';
		this.opening_date = null;

		this.price_domicile = 0;
		this.price_tip = 0;	//precio propina
		this.shipping_address = null;
		this.status_payment = '';
		this.store_pickup = true;	//recoger en tienda	
		this.total_to_pay = 0;
		this.turn_local_reference = null;
		this.active_tip = false;
		this.total_taxes = 0;
		this.total_base = 0;
		this.subtotal = 0;
		// naren crear esta coumna en bd
		this.from_seller = false;
	}
	createServerOrder() {
		/*
		return new Promise((resolve, reject) => {
		  this.order['createdAt'] = (new Date()).getTime();
		  this.rest.post('domicile/create-order', this.order).subscribe((response: any) => {
			if(response.code == 'OK' && response.item) {
			  //console.log(response)  
			  resolve(response.item.id);
			}else{
			  reject(response);
			}
		  }, (err) => {
			  reject(err);
		  });      
		});
	*/
	}

	getActiveTip() {
		return this.active_tip;
	}

	getBillCashRegisterId() {
		return this.bill_cash_register_id;
	}
	getBusPersonCashierId() {
		return this.bus_person_cashier_id;
	}

	getBusPersonCustomerId() {
		return this.bus_person_customer_id;
	}

	getBusPersonDomiciliaryId() {
		return this.bus_person_domiciliary_id;
	}

	getBusPersonSellerId() {
		return this.bus_person_seller_id;
	}

	getTotalDiscounts() {
		return this.total_discounts;
	}

	getCoupon() {
		return this.coupon;
	}
	getDataCashier() {
		return this.data_cashier;
	}
	getConsecutiveOrder() {
		return this.consecutive_order;
	}
	getConsecutiveOrderText() {
		return this.consecutive_order_text;
	}
	getDataConsecutive() {
		return this.data_consecutive;
	}
	getDataCustomer() {
		return this.data_customer;
	}
	getDataDomiciliary() {
		return this.data_domiciliary;
	}
	getDataSeller() {
		return this.data_seller;
	}
	getDateSync() {
		return this.date_sync;
	}

	getIsOnTable() {
		return this.is_on_table;
	}
	getIsDomicile() {
		return this.is_domicile;
	}
	getIsElectronicInvoice() {
		return this.is_electronic_invoice;
	}
	getIsOpen() {
		return this.is_open;
	}
	getIsSync() {
		return this.is_sync;
	}
	getNote() {
		return this.note;
	}
	getNameTable() {
		return this.name_table;
	}
	getPriceDomicile() {
		return this.price_domicile;
	}
	getPriceTip() {
		return this.price_tip;
	}
	getPriceTotalWithAdds() {
		return this.getTotalToPay() + parseFloat(this.price_tip) + parseFloat(this.price_domicile);
	}
	getTurnLocalReference() {
		return this.turn_local_reference;
	}

	getPriceUnitarySubItem(item) {
		return item.unit_price;
	}

	setActiveTip(value) {
		this.active_tip = value;
	}

	setCoupon(value) {
		this.coupon = value;
	}
	setIsOpen(value) {
		this.is_open = value;
	}

	setIsSync(value) {
		this.is_sync = value;
	}

	setCanceled(value) {
		this.canceled = value;
	}
	setCancellationDate(value) {
		this.cancellation_date = value;
	}
	setCancellationReason(value) {
		this.cancellation_reason = value;
	}

	setDateOrder(value) {
		this.date_order = value;
	}

	setInvoiceWasGenerated(value) {
		this.invoice_was_generated = value;
	}

	setInvoiceId(value) {
		this.invoice_id = value;
	}

	setBusSubsidiaryId(value) {
		this.bus_subsidiary_id = value;
	}
	setBillCashRegisterId(value) {
		this.bill_cash_register_id = value;
	}
	setBillCashRegisterTurnId(value) {
		this.bill_cash_register_turn_id = value;
	}
	setTurnLocalReference(value) {
		this.turn_local_reference = value;
	}
	setBusPersonCashierId(value) {
		this.bus_person_cashier_id = value;
	}
	setDataCashier(value) {
		this.data_cashier = value;
	}
	setDateSync(value) {
		this.date_sync = value;
	}
	setNameTable(value) {
		this.name_table = value;
	}
	setClosingDate(value) {
		this.closing_date = value;
	}
	setTotalDiscounts(value) {
		this.total_discounts = value;
	}
	setIsDomicile(value) {
		this.is_domicile = value;
	}
	setIsElectronicInvoice(value) {
		this.is_electronic_invoice = value;
	}
	setIsOnTable(value) {
		this.is_on_table = value;
	}
	setNameTable(value) {
		this.name_table = value;
	}
	setNote(value) {
		this.note = value;
	}
	setOrderStatus(value) {
		this.order_status = value;
	}
	setPriceDomicile(value) {
		this.price_domicile = value;
	}

	setDataConsecutive(value) {
		this.data_consecutive = value;
	}

	setConsecutiveOrder(value) {
		this.consecutive_order = value;
	}

	setConsecutiveOrderText(value) {
		this.consecutive_order_text = value;
	}

	setPriceTip(value) {
		if (!this.active_tip) {
			this.price_tip = parseInt(0);
		} else {
			if (value == null) {
				this.price_tip = (this.total_to_pay * 10) / 100;
			} else {
				this.price_tip = parseInt(value);
			}
		}

	}
	setStorePickup(value) {
		this.store_pickup = value;
	}

	setDataTaxes(value) {
		this.data_taxes = value;
	}

	setTotalTaxes(value) {
		this.total_taxes = value;
	}

	setTotalBase(value) {
		this.total_base = value;
	}

	setSubtotal(value) {
		this.subtotal = value;
	}

	addPayment(payment) {
		return new Promise(async (resolve, reject) => {
			if (this.data_payments == null) {
				this.data_payments = [];
			}
			this.data_payments.push(payment);
			resolve(payment);
		});
	}
	getBalance() {
		return this.getPriceTotalWithAdds() - this.getTotalPayments();
	}
	getCustomerInfo() {
		return this.data_customer;
	}

	setCustomer(customer) {
		if (!customer) {
			this.bus_person_customer_id = '';
			this.data_customer = null;
			return;
		}
		if (customer.id) {
			this.bus_person_customer_id = customer.id;
		}
		this.data_customer = customer;
	}
	setDomiciliary(person) {
		if (!person) {
			this.bus_person_domiciliary_id = null;
			this.data_domiciliary = null;
			return;
		}
		if (person.id) {
			this.bus_person_domiciliary_id = person.id;
		}
		this.data_domiciliary = person;
	}
	setSeller(person) {
		if (!person) {
			this.bus_person_seller_id = null;
			this.data_seller = null;
			return;
		}
		if (person.id) {
			this.bus_person_seller_id = person.id;
		}
		this.data_seller = person;
	}
	getCustomerName() {
		let last = '';
		if (this.data_customer.last_name) {
			last = this.data_customer.last_name;
		}
		return this.data_customer.first_name + ' ' + last;
	}
	getName() {
		return this.data_customer.first_name;
	}
	getLastName() {
		let last = '';
		if (this.data_customer.last_name) {
			last = this.data_customer.last_name;
		}
		return last;
	}
	getInvoiceId() {
		return this.invoice_id;
	}
	createReference() {
		return '_' + (new Date()).getTime();
	}

	deleteItem(posManager, item) {
		//console.log(item)
		//console.log(this.data_items)
		const idx = this.data_items.findIndex((it) => it.id == item.id);
		this.data_items.splice(idx, 1);
	}

	deletePayment(posManager, item) {
		//console.log(item)
		//console.log(this.data_items)
		const idx = this.data_payments.findIndex((it) => it._id == item._id);
		this.data_payments.splice(idx, 1);
		this.is_open = true;
	}

	deleteAllPayments(posManager) {
		this.data_payments = [];
		this.is_open = true;
	}

	addItem(posManager, product, variant, qt, note, options, discount, new_price, price_list_id) {
		//console.log(options)
		return new Promise(async (resolve, reject) => {

			if (!qt) {
				qt = 1;
			}
			const item = await ItemLine.createItem(posManager, this.createReference(), product, variant, qt, note, options, discount, new_price, price_list_id);
			this.data_items.push(item);
			resolve(item);
		});
	}

	updateItem(posManager, itemLine, product, variant, qt, note, options, discount, new_price, price_list_id) {
		return new Promise(async (resolve, reject) => {

			if (!qt) {
				qt = 1;
			}

			const item = await ItemLine.createItem(posManager, itemLine.id, product, variant, qt, note, options, discount, new_price, price_list_id);
			//console.log(item)
			const idx = this.data_items.findIndex((it) => it.id == itemLine.id)
			if (idx >= 0) {
				this.data_items[idx] = item;
			}
			resolve(item);

		});
	}

	isEmpty() {
		return this.getTotalItems() == 0;
	}

	getPayments() {
		if (this.data_payments) {
			return this.data_payments;
		}
		return [];
	}

	getTotalItems() {
		let total = 0;
		if (this.data_items) {
			for (const itemLine of this.data_items) {
				total += parseFloat(itemLine.quantity);
			}
		}
		return total;

	}

	getItems(getAll) {
		let items = [];
		if (this.data_items) {
			if (getAll) {
				items = this.data_items;
			} else {
				for (const itemLine of this.data_items) {
					if (getAll == false) {
						if (itemLine.printed == false) {
							items.push(itemLine);
						}
					}
				}
			}
		}
		return items;
	}

	getTotalItemsPrintRemote() {
		let count = 0;
		if (this.data_items) {
			for (const itemLine of this.data_items) {
				if (itemLine.printed == false) {
					count++;
				}
			}
		}
		return count;
	}

	setDataItemsPrinted() {
		for (const i in this.data_items) {
			if (this.data_items.hasOwnProperty(i)) {
				this.data_items[i].printed = true;
			}
		}
	}

	getTotalPayments() {
		let total = 0;
		if (this.data_payments) {
			for (const itemLine of this.data_payments) {
				total += itemLine.value;
			}
		}
		return total;
	}

	getTotalToPay() {
		let total = 0;
		if (this.data_items) {
			for (const itemLine of this.data_items) {
				total += ItemLine.getTotal(itemLine);
			}
		}
		this.total_to_pay = total;
		return total;
	}

	getSubtotal() {
		let total = 0;
		if (this.data_items) {
			for (const itemLine of this.data_items) {
				total += ItemLine.getSubtotal(itemLine);
			}
		}
		return total;
	}

	getTotalDiscount() {
		let total = 0;
		if (this.data_items) {
			for (const itemLine of this.data_items) {
				total += ItemLine.getTotalDiscount(itemLine);
			}
		}
		return total;
	}

	getTotalRealDiscount() {
		let total = 0;
		if (this.data_items) {
			for (const itemLine of this.data_items) {
				total += ItemLine.getTotalRealDiscount(itemLine);
			}
		}
		return total;
	}

	getTotalTaxes() {
		let total = 0;
		if (this.data_items) {
			for (const itemLine of this.data_items) {
				total += ItemLine.getTotalTaxes(itemLine);
			}
		}
		return total;
	}

	removeItem() {

	}

	getCustomer() {
		return null;
	}

	static create(data) {
		const order = new Order();
		for (const i in data) {
			order[i] = data[i];
		}
		return order;
	}

	//print comandas
	async printRemote(store, printAll, filters, db) {



		/*let categoriesForPrinter = null;
		if(printConfig && printConfig.config_categories_by_printer){
			categoriesForPrinter = printConfig.config_categories_by_printer;
		}*/


		// por defecto todas las impresiones irian a la impresora POS
		// si categoriesForPrinter tiene configurado una categoria para una impresora, se imprimiran las ordenes de esa categoria ademas de la factura
		// ej: si printKitchenOrderSeparately, printBarOrderSeparately estan en true, se imprimen 2 tirillas por separado
		// si categoriesForPrinter tiene configuracion, por defecto todas van a pos, pero si hay una  impresora kitchen, se envian a esa
		// si hay mas impresoras, se envian a la que corresponda

		// se debe tener en cuenta que si se imprime por separado, se debe imprimir la factura al final
		// se deben agrupar los items por las categorias configuradas para su posterior impresion

		/*itemsByPrinter = {
			'bar': [{}, {}],
			'cocina': [{}, {}],
		}

		textPrint = {
			'bar': '...........',
			'cocina': '...........'
		}*/

		let fontSize = '15';
		let installation = store.getters.installation;
		if (installation) {
			const cashRegister = await db.getCashRegister(
				installation.cash_id
			);
			if (cashRegister) {
				if (cashRegister.config) {
					if (cashRegister.config.pos_printer_font_size) {
						fontSize = cashRegister.config.pos_printer_font_size;
					}
				}
			}
		}
		const receipt = new utilReceiptPOS(store, fontSize, filters, db);
		let tableItems = [];
		await receipt.setDataCompany();
		receipt.setDate((new Date(this.date_order)).toLocaleDateString());
		receipt.setTitle("Comanda");
		receipt.setReference(this.local_reference);
		if (this.name_table) {
			receipt.setTable(this.name_table);
		}
		await receipt.addHeader();
		for (const row of this.getItems(printAll)) {
			let product_name = '';
			let item = [];
			if (row['product']) {
				if (row['product']['name']) {
					product_name = row['product']['name'];
				}
			}
			if (row['product_variant']) {
				if (row['product_variant']['reference']) {
					product_name += ' - '.row['product_variant']['reference'];
				}
			}
			item.push(product_name + ' x' + row['quantity']);
			if (row['options']) {
				for (const i in row['options']) {
					if (row['options'].hasOwnProperty(i)) {
						if (row['options'][i].option_type == 'custom') {
							if (row['options'][i].name) {
								item.push('**' + row['options'][i].name);
								if (row['options'][i].value && row['options'][i].value.length) {
									for (const cus of row['options'][i].value) {
										item.push('->' + cus);
									}
								}
							}
						}
					}
				}
				if (row['sub_items'] && row['sub_items'].length) {
					for (const sub of row['sub_items']) {
						if (sub.product) {
							item.push('**' + sub.product.name + ' x' + sub.quantity);
						}
					}
				}
			}
			if (row['note']) {
				item.push('Nota:' + row['note']);

			}
			tableItems.push(item);
		}
		receipt.addTable(tableItems, true, (fontSize - 1));
		if (this.note) {
			receipt.addText(this.note, 'justify', (fontSize - 1));
		}
		receipt.addFooter();

		// printManager.printFromFormat(receipt.render(), store, filters, db, 'commands');
		await printManager.printFromFormat(receipt.render(), store, filters, db);
	}

	// print factura - recibo
	async printReceipt(store, screen, filters, db, printConfig = null) {
		let fontSize = '15';
		let installation = store.getters.installation;
		if (installation) {
			const cashRegister = await db.getCashRegister(
				installation.cash_id
			);
			if (cashRegister) {
				if (cashRegister.config) {
					if (cashRegister.config.pos_printer_font_size) {
						fontSize = cashRegister.config.pos_printer_font_size;
					}
				}
			}
		}
		const receipt = new utilReceiptPOS(store, fontSize, filters, db);
		let tableItems = [];
		await receipt.setDataCompany();
		receipt.setDate((new Date(this.date_order)).toLocaleDateString());
		/*
		if (this.is_electronic_invoice) {
			receipt.setReference('#' + this.local_reference);
		} else {
		*/
		if (this.consecutive_order_text) {
			receipt.setReference(this.consecutive_order_text);
		} else {
			receipt.setReference('#' + this.local_reference);
		}
		// }

		if (!screen) {
			let data_regimes = store.getters.company.regimen;
			let type_document = 'RECIBO';
			
			if (store.getters.getGeneralValue('gen_billing_invoice_electronically') == true) {
				type_document = 'COMPROBANTE DE ENTREGA';
			}

			if (this.order_status != 'create') {
				if (this.order_status == 'pending') {
					type_document = 'Esta orden esta abierta';
					receipt.setReference(null);
				}
				if (this.order_status == 'anulled') {
					type_document = 'Esta orden esta anulada';
					receipt.setReference(null);
				}

			}
			receipt.setTitle(type_document);
			if (this.due_date) {
				receipt.setDueDate(this.due_date);
			}
		}


		if (this.name_table) {
			receipt.setTable(this.name_table);
		}

		if (this.data_cashier) {
			receipt.setCashier(this.data_cashier.name);
		}
		if (this.data_domiciliary) {
			receipt.setDomiciliary(this.data_domiciliary.name);
		}


		if (this.data_seller) {
			receipt.setSeller(this.data_seller.name);
		}

		if (this.data_customer) {
			receipt.setTo(this.data_customer);
		}

		await receipt.addHeader();


		let totalItem = 0;
		let totalInvoice = 0;
		for (const row of this.getItems(true)) {
			let product_name = '';
			let item = [];
			let reference = '';
			if (row['product']) {
				if (row['product']['name']) {
					product_name = row['product']['name'];
				}
				if (row['product']['reference']) {
					reference = row['product']['reference'];
				}
			}
			if (row['product_variant']) {
				if (row['product_variant']['reference']) {
					product_name += ' - ' + row['product_variant']['reference'];
					reference = row['product_variant']['reference'];
				}
			}
			item.push('**REF: ' + reference);
			item.push(product_name);
			totalItem = (row['quantity'] * row['unit_price']);
			totalInvoice += totalItem;

			let textDiscount = '';
			if (row['percentage_discount'] > 0) {
				textDiscount = '  (-' + row['percentage_discount'] + '%) ';
			}
			item.push(row['quantity'] + 'x     ' + filters.currency(row['unit_price'], 0) + textDiscount + ' = ' + filters.currency(totalItem - row['real_discount'], 0));
			item.push("");
			if (row['options']) {
				if (row['sub_items'] && row['sub_items'].length) {
					for (const sub of row['sub_items']) {
						if (sub.product) {
							item.push('**REF: ' + sub.product.reference);
							item.push(sub.product.name);
							item.push(sub.quantity + 'x     ' + filters.currency(this.getPriceUnitarySubItem(sub), 0) + '    = ' + filters.currency((this.getPriceUnitarySubItem(sub) * sub.quantity), 0));
							item.push("");
						}
					}
				}
			}
			tableItems.push(item);
		}
		receipt.addTable(tableItems, false, (fontSize - 1));

		receipt.pushTotal('Subtotal', totalInvoice, fontSize);
		receipt.pushTotal('Descuento', this.getTotalRealDiscount(), fontSize);
		receipt.pushTotal('Base', this.getTotalToPay() - this.getTotalTaxes(), fontSize);

		if (this.data_taxes) {
			for (let idx in this.data_taxes) {
				receipt.pushTotal(this.data_taxes[idx].name, parseFloat(this.data_taxes[idx].tax_amount), fontSize);
			}
		}
		receipt.pushTotal('Total', this.getTotalToPay(), fontSize);

		let received = 0;
		let change = 0;
		if (this.data_payments) {
			for (const pay of this.data_payments) {
				received += (pay.value + pay.change);
				change += pay.change;
			}
		}

		receipt.pushPayment('Recibido', received, fontSize);
		receipt.pushPayment('Cambio', change, fontSize);

		if (this.price_tip) {
			receipt.pushTotal('Propina', this.price_tip, fontSize);
		}
		if (this.price_domicile) {
			receipt.pushTotal('Domicilio', this.price_domicile, fontSize);
		}
		if (this.price_tip || this.price_domicile) {
			receipt.pushTotal('Total', this.getPriceTotalWithAdds(), fontSize);
		}

		receipt.addTotals();

		receipt.addPayments();

		if (this.price_tip) {
			let msg_tip = store.getters.getGeneralValue('gen_sales_tip_msg');
			if (msg_tip) {
				receipt.setMsgTip(msg_tip);
			}
		}

		if (this.note) {
			receipt.addText(this.note, 'justify', (fontSize - 1));
		}
		if (!screen) {
			if (this.data_consecutive && this.data_consecutive.text_footer_page) {
				receipt.setFooternote(this.data_consecutive.text_footer_page);
			}			

			if (store.getters.getGeneralValue('gen_billing_invoice_electronically') == true) {
				receipt.addText(receipt.title, 'center', (fontSize - 1));
				receipt.addText('ESTE DOCUMENTO NO ES UNA FACTURA DE VENTA', 'center', (fontSize - 1));				
				if(this.getInvoiceId() != null){
					receipt.addText('Consulta tu factura en el correo electrónico registrado, escaneando el siguiente QR', 'center', (fontSize - 1));				
					// With async/await
					let QRbase64 = await new Promise((resolve, reject) => {
						QRCode.toDataURL(process.env.VUE_APP_BASECONFIG_URL_PUBLIC_INVOICE + this.getInvoiceId(), { 
							errorCorrectionLevel: 'H',
							type: 'image/png',
							width: 100,
							margin: 1
						 }, function (err, code) {
							if (err) {
								reject(reject);
								return;
							}
							resolve(code);
						});
					});
					if(QRbase64){
						receipt.addImageBase64(QRbase64, '100px', '100px');
						receipt.addText('O visitando el siguiente enlace: ', 'center', (fontSize - 1));
						receipt.addText(process.env.VUE_APP_BASECONFIG_URL_PUBLIC_INVOICE + this.getInvoiceId(), 'center', (fontSize - 1));
						receipt.addSpacing();
					}					
				}
			}
		}		
		receipt.addFooter();
		await printManager.printFromFormat(receipt.render(), store, filters, db);
	}


	async getPrintConfig(printConfig) {
		//let printConfig = await this.db.getPrintConfig();
		//console.log(printConfig)

		let defaultConfig = {
			"bill_cash_register_id": null,
			"printers": [
				{
					"device_name": "POS",
					"printer_target": "POS",
					"width": "48mm",
					"description": null,
					"is_default_create": true
				}
			],
			"config_categories_by_printer": null,
			/*[ { "printer_name": "bar", "category": { "id": "e485c5e3-1984-4f19-a08d-aaa8245044df", "name": "aperiam placeat" } }, ],*/
			"print_order_accepted": true,
			"print_invoice_order_aceppted": false
		}

		if (printConfig) {
			defaultConfig['bill_cash_register_id'] = printConfig.bill_cash_register_id;
			if (printConfig.printers && printConfig.printers.length > 1) {
				defaultConfig['printers'] = printConfig.printers;
			}
			if (printConfig.config_categories_by_printer && printConfig.config_categories_by_printer.length > 1) {
				defaultConfig['config_categories_by_printer'] = printConfig.config_categories_by_printer;
			}
			defaultConfig['print_order_accepted'] = printConfig.print_order_accepted;
			defaultConfig['print_invoice_order_aceppted'] = printConfig.print_invoice_order_aceppted;
		}

		return defaultConfig;
	}

	getPrinterPosDefault(printConfig) {
		let printers = printConfig.printers;
		for (const p of printers) {
			if (p.printer_target.toLowerCase() == 'pos') {
				return p;
			}
		}
	}

	// de acuerdo a lo que se requiera imprimir, bar, kitchen o POS, determina cual de las impresoras usará
	// si se requiere imprimir por separado, busca la impresora que tenga el mismo nombre que el tipo de impresión
	// si esta configurado categorias por impresora, busca la impresora que tenga la categoria que corresponde al tipo de impresión
	getPrinterForPrint(printConfig, type) {
		let ty = type.toLowerCase();

		let printers = printConfig.printers;
		let printer = this.getPrinterPosDefault(printConfig);



		return printer;
	}

}

export default Order;