
class LocalStorage{
	constructor(adapter){
		this.adapter = adapter;
		//this.databaseInitialize();
	}

	async getInstallation(){
		return await this.adapter.getInstallation();
	}

	async saveTurn(turn, http) {
		return await this.adapter.saveTurn(turn, http);
	}

	async getPrintConfig() {
		return await this.adapter.getPrintConfig();
	}
	async itsInstalled(){
		return this.adapter.itsInstalled();
	}
	async install(data){
		await this.adapter.install(data);
	}
	async saveOrder(order, http){
		return this.adapter.saveOrder(order, http);
	}
	async saveOrderRemote(order, http){		
		return this.adapter.saveOrderRemote(order, http);
	}
	async findAndUpdateBy(field, item, collection) {

		return this.adapter.findAndUpdateBy(field, item, collection);
	}
	async getPriceLists(){
		return await this.adapter.getPriceLists();
	}
	async updateOrders(items) {
		return await this.adapter.updateOrders(items);
	}
	async getAllOrders(){
		return await this.adapter.getAllOrders();
	}
	async getAllCategories() {
		return  this.adapter.getAllCategories();
	}

	async getSaleOptions(){
		return await this.adapter.getSaleOptions();
	}

	async getOpenTurn(){
		return this.adapter.getOpenTurn();
	}

	async getConsecutiveById(id){
		return this.adapter.getConsecutiveById(id);
	}

	async getConsecutives(){
		return this.adapter.getConsecutives();
	}

	async saveTurn(turn, http){
		return this.adapter.saveTurn(turn, http);
	}	

	async getVariants(){
		return await this.adapter.getVariants();
	}

	async getTax(id) {
		return await this.adapter.getTax(id);
	}

	async getSaleOption(id) {
		return await this.adapter.getSaleOption(id);
	}

	async getCashRegister(id){
		return await this.adapter.getCashRegister(id); 
	}

	async getEmployeesByType(type){
		return await this.adapter.getEmployeesByType(type); 
	}

	containsOptions(prdId, options) {
		return this.adapter.containsOptions(prdId, options)
	}

	async getOrdersByTurn(turn){
		return this.adapter.getOrdersByTurn(turn);
	}
	async getOpenOrdersByTurn(turn){
		return this.adapter.getOpenOrdersByTurn(turn);
	}

	async getOrdersNotSyncro(){
		return this.adapter.getOrdersNotSyncro();
	}

	async getOrderByConsecutive(value){
		return await this.adapter.getOrderByConsecutive(value); 
	}

	async getTurnsNotSyncro(){
		return this.adapter.getTurnsNotSyncro();
	}

	async getProduct(id) {
		return await this.adapter.getProduct(id);
	}
	async getTemplate(id) {
		return await this.adapter.getTemplate(id);
	}

	async getTemplateByName(value) {
		return this.adapter.getTemplateByName(value);
	}

	async getProductVariants(variantId) {
		return this.adapter.getProductVariants(variantId);
	}

	load(){
		// return this.adapter.load();
	}
	databaseInitialize() {
	  //if (!this.adapter.getCollection("products")) {

	  //}
	}
	removeCollections(){
		//this.adapter.removeCollection("products");
	  //this.adapter.removeCollection("price_list");
	  //this.adapter.removeCollection("zones");
	}

	async updateItems(nameCollection, items){
		return await this.adapter.updateItems(nameCollection, items);
	}

	async createCollections(){
		this.adapter.createCollections();
	}

	addCollection(name){
		return this.adapter.addCollection(name);
	}
    getCollections(){
  	  return this.adapter.getCollections();
    }
	getCollection(name){
		return this.adapter.getCollection(name);
	}
	removeCollection(name){
		return this.adapter.removeCollection(name);
	}

	deleteAll(name){
		return this.adapter.deleteAll(name);
	}

	getTurn(local){
		return this.adapter.getTurn(local);
	}

	serialize(){
		return this.adapter.serialize();
	}

	loadDB(data){
		return this.adapter.loadDB(data);
	}

	//options_order totals_section
//1036653006N15  6545353890355
	searchVariant(prdId, options){
		return this.adapter.searchVariant(prdId, options);
	}
	searchProductVariantByCodeBarOrReference(params, limit, sortField){
		return this.adapter.searchProductVariantByCodeBarOrReference(params, limit, sortField);
	}
	searchProductByCodeBarOrReference(params, limit, sortField){
		return this.adapter.searchProductByCodeBarOrReference(params, limit, sortField);
	}
	searchProducts(params, limit, sortField, offset){
		return this.adapter.searchProducts(params, limit, sortField, offset);
	}

	async searchLocalOrders(params, limit, page, sortField) {
		return await this.adapter.searchLocalOrders(params, limit, page, sortField);
	}

	async searchLocalTurns(params, limit, page, sortField) {
		return await this.adapter.searchLocalTurns(params, limit, page, sortField);
	}

	getTotalLocalInvoices(){
		return this.adapter.getTotalLocalInvoices();
	}

	getTotalLocalTurns(){
		return this.adapter.getTotalLocalTurns();
	}

	async getOrdersByType(type){
		return await this.adapter.getOrdersByType(type);
	}

	async getOpenOrders(type){
		return await this.adapter.getOpenOrders(type);
	}

	async getTableByName(name){
		return await this.adapter.getTableByName(name);
	}


	async getPaymentMethods(){
		return await this.adapter.getPaymentMethods();
	}

	async getTables(){
		return await this.adapter.getTables();
	}	
	
	async updateConsecutiveCurrentNumber(objConsecutive){
		return await this.adapter.updateConsecutiveCurrentNumber(objConsecutive);
	}

	async updateTableBusy(table, value){
		return await this.adapter.updateTableBusy(table, value);
	}

	async cleanLocalOrders(date){
		return await this.adapter.cleanLocalOrders(date);
	}

	async cleanLocalTurns(date){
		return await this.adapter.cleanLocalTurns(date);
	}
}


export default LocalStorage;